//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base';
@import 'layout/layout';
@import 'react-datepicker/dist/react-datepicker';
@import '../../../app/pages/login/custom.scss';
// html{
//   // text-transform: capitalize;
// }
// input[type='text']:not(.rbt-input-main) {
//   text-transform: capitalize;
// }
input[type='email'] {
  text-transform: initial;
}
// .rbt-input-main{
//   text-transform:lowercase;
// }
#kt_content_container {
  margin-bottom: 40px;
}
.table-responsive {
  max-height: calc(100vh - 310px);
}
.table-responsive.timesheetEntry {
  max-height: unset !important;
}
.table-responsive.reports-table {
  max-height: calc(100vh - 335px);
}
.table-responsive.reports-table-comp {
  max-height: calc(100vh - 275px);
}
.table-responsive.reports-table.side-graph {
  max-height: 332px;
}
.table-responsive table th {
  position: sticky;
  top: 0;
  white-space: nowrap;
  background-color: var(--kt-card-bg);
  z-index: 96;
}

td {
  vertical-align: middle;
}
.HistoryTable td {
  vertical-align: top;
}
.approverTableTdAlignTop td {
  vertical-align: top;
}

.rbt-close-content {
  display: none;
}
.table-responsive .formControlMinWidth90 {
  min-width: 90px;
}
.table-responsive .formControlMinWidth45 {
  min-width: 50px;
}

.tableTdBlueBg {
  background-color: rgba(0, 158, 247, 0.08);
  border-left: 1px solid #66a9cf26;
  border-right: 1px solid #66a9cf26;
}
.tableTdPinkBg {
  background-color: rgba(203, 124, 192, 0.08);
  // border-left:1px solid #cb7cc054;
  // border-right:1px solid #cb7cc054
  border-left: 1px solid #66a9cf26;
  border-right: 1px solid #66a9cf26;
}
.tableTdYellowBg {
  background-color: #fffaef;
  // border-left:1px solid #fbe5b4;
  // border-right:1px solid #fbe5b4;
  border-left: 1px solid #66a9cf26;
  border-right: 1px solid #66a9cf26;
}
.tableTdRedBg {
  background-color: rgba(244, 40, 41, 0.12);
  border-left: 1px solid #66a9cf26;
  border-right: 1px solid #66a9cf26;
}
// .disabledColorForApprover{
//   input{
//     text-align: center;
//   }
// }
[data-theme='dark'] {
  .tableTdYellowBg {
    background-color: #38352d;
  }
  .disabledColorForApprover {
    .form-control:disabled,
    .form-control[readonly] {
      background-color: #2b2b40;
    }
  }
  .orgLogo {
    filter: invert(1);
    -webkit-filter: invert(1);
  }
  .apexcharts-tooltip {
    background-color: #f5f8fa !important;
    color: black;
  }
  .highcharts-tooltip path {
    fill: #f5f8fa;
  }
}
[data-theme='light'] {
  .disabledColorForApprover {
    .form-control:disabled,
    .form-control[readonly],
    .disabledBg {
      background-color: #f5f8fa !important;
    }
  }
  .apexcharts-tooltip {
    background-color: #cacacc !important;
    color: 'black';
  }
  .highcharts-tooltip path {
    fill: #cacacc;
  }
}

.timeEntryIcons {
  color: White;
  width: 22px;
  height: 20px;
  padding: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin: 0 1px;
}
.blueBg {
  background-color: #5d9ed3;
}

.yellowBg {
  background-color: #fbc02d;
}
.pinkBg {
  background-color: #cb7cc0;
}
.redBg {
  background-color: rgba(244, 40, 41, 0.12);
}

.pinkBgLight {
  background-color: rgba(203, 124, 192, 0.47);
}
.btn.icon-btn:hover {
  .pinkBgLight {
    background-color: #cb7cc0;
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: unset !important;
  cursor: not-allowed !important;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: right calc(1.375em + 0.3875rem) center !important;
}

.uploadDocument {
  // height:100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-align: center;
}
.downloadIcon {
  width: 35px;
  height: 35px;
}
.calContainer {
  padding: 10px;
}
.calImage {
  width: 40px;
  height: 40px;
  // padding:15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.buttonCal {
  width: 20px;
}
.vh-75 {
  min-height: 60vh;
}
.chooseOrganizationCard {
  width: 200px;
  height: 200px;
  vertical-align: middle;
  display: table-cell;
  cursor: pointer;
  box-shadow: 0px 7px 13px -3px rgb(0 0 0 / 30%), 0px 2px 4px rgb(0 0 0 / 40%),
    inset 0px -3px 0px rgb(0 0 0 / 20%);
  border-radius: 10px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
}
.chooseOrganizationCard:hover {
  box-shadow: unset;
  color: white;
}
.chooseOrganizationCard1 {
  background: #ab47bc;
}
.chooseOrganizationCard2 {
  background: #7e57c2;
}
.chooseOrganizationCard3 {
  background: #5c6bc0;
}
.chooseOrganizationCard4 {
  background: #2196f3;
}
.chooseOrganizationCard5 {
  background: #26a69a;
}
.chooseOrganizationCard6 {
  background: #00acc1;
}
.chooseOrganizationCard7 {
  background: #757575;
}
.chooseOrganizationCard8 {
  background: #689f38;
}
.chooseOrganizationCard9 {
  background: #8d6e63;
}
.accordianHeaderButton button {
  background-color: transparent !important;
  box-shadow: unset !important;
}
.accordion-body {
  padding-top: 0 !important;
}
.accordion-button:not(.collapsed) {
  color: var(--kt-input-disabled-color);
}
.approverSearchCard {
  width: 250px;
  // max-height: 70vh;
  // min-height: 200px;
  max-height: calc(100vh - 310px);
  min-height: calc(100vh - 310px);
}
.approverBadge {
  width: 40px;
  height: 40px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.approverSearchResult {
  max-height: calc(100vh - 470px);
  overflow-y: auto;
  overflow-x: hidden;
}
.btn.btn-icon.buttonIconInApprover {
  width: 30px;
  height: 30px;
}
.aside.aside-dark.aside-hoverable:hover #kt_aside_toggle {
  display: none;
}
.svg-icon.svg-icon-1.svg-icon-Custom-Height svg {
  height: 2.75rem !important;
}
// .splash-screen img{
//     height:350px !important;
// }
.textFieldMinHeight {
  min-height: 30px;
}
.dateChangeMinWidth {
  display: inline-block;
  width: 160px;
}
// .disabledBg {
//   background-color: var(--kt-input-disabled-bg) !important;
// }
.react-datepicker-popper {
  z-index: 99 !important;
}
.react-datepicker {
  font-family: inherit;
  font-size: 0.8rem;
  background-color: var(--kt-body-bg) !important;
  color: var(--kt-gray-900) !important;
  border: unset !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px,
    rgb(0 0 0 / 12%) 0px 3px 14px 2px !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}
.react-datepicker__header {
  text-align: center;
  background-color: var(--kt-body-bg) !important ;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--kt-gray-700) !important;
  display: inline-block;
  width: 2rem !important;
  line-height: 2rem !important;
  text-align: center;
  margin: 0.3rem !important;
  font-size: 12px;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: var(--kt-gray-800) !important;
  font-weight: bold;
  font-size: 1rem;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 20px !important;
  background-color: var(--kt-primary) !important;
  color: #fff !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 20px !important;
  background-color: #f0f0f0;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: var(--kt-body-bg) !important ;
}
.hoverWeek .react-datepicker__week:hover .react-datepicker__day {
  border-radius: 20px !important;
  background-color: #f0f0f0;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc !important;
}
.singleFile {
  border: 1px solid grey;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 10px 0;
}
.margin-left-250 {
  margin-left: 250px;
}
.approveBg {
  background: linear-gradient(90deg, #2af598 0%, #2bafff 100%);
}
.rejectBg {
  background: linear-gradient(90deg, #f093fb 0%, #f5576c 100%);
}
.pendingBg {
  background: linear-gradient(90deg, #fa709a 0%, #fee140 100%);
}
.pending {
  width: auto;
  height: 103px;

  /* card box shadow */

  box-shadow: 0px 0px 20px rgba(76, 87, 125, 0.02);
  border-radius: 8px;
}
.imgDiv {
  background: #fff;
  width: 70px;
  height: 70px;
  border-radius: 15px;
  position: relative;
  margin: 0 15px;
}
.PendingImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.calMonthView {
  width: 100%;
  overflow-x: auto;
}
.calMonthView table {
  width: 100%;
}
.calMonth {
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.calMonthView thead tr th {
  background-color: var(--kt-text-gray-200);
}
.monthHours {
  color: #5c81fa;
}
.calMonthView td {
  width: 100px;
  min-width: 100px;
  border: 1px solid var(--bs-table-border-color);
  padding: 0 !important;
}
.dayContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.timeSheetBackDrop .modal-content {
  text-align: center;
  display: flex;
  align-items: center;
  background: none;
}
.input-group-container {
  position: relative;
}
.input-group-container input {
  padding-right: 30px;
}
.input-group-container i {
  position: absolute;
  right: 15px;
  top: 14px;
  cursor: pointer;
}

.input-group-container i.is-invalid {
  position: absolute;
  right: 35px;
  top: 14px;
  cursor: pointer;
}

[role='tooltip'].popup-content {
  width: auto !important;
}

.profileNameCard {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 40px;
}

.disabledColorForApprover {
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #f5f8fa;
  }
}
.ql-editor {
  min-height: 300px;
}
.firstLetterCapital:first-letter {
  text-transform: uppercase !important;
}
.overflowHidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // width:190px;
}
.text-transform-unset {
  text-transform: none;
}
.invisibleBlock {
  visibility: hidden;
  height: 30px;
  width: 0;
}
.badge-min-width-80 {
  min-width: 80px;
  justify-content: center;
}
.width100 {
  width: 100%;
}
.h85 {
  height: 85px;
}
.h45 {
  height: 43px;
}
.fs50 {
  font-size: 35px;
}
.timesheetScrollContainer {
  max-height: calc(100vh - 360px);
  overflow-y: auto;
  overflow-x: hidden;
}
.timesheetScrollContainer.ApproverMultiWithoutAll {
  max-height: calc(100vh - 310px);
  overflow-y: auto;
  overflow-x: hidden;
}
.timesheetScrollContainer.ApproverMultiWithAll {
  max-height: calc(100vh - 360px);
  overflow-y: auto;
  overflow-x: hidden;
}
.timesheetScrollContainer.ApproverCalWithButton {
  max-height: calc(100vh - 360px);
  overflow-y: auto;
  overflow-x: hidden;
}
.timesheetScrollContainer.ApproverCalWithOutButton {
  max-height: calc(100vh - 310px);
  overflow-y: auto;
  overflow-x: hidden;
}
.timesheetScrollContainer.timesheetScrollContainerForSingleProject {
  max-height: calc(100vh - 320px) !important;
}
.timesheetScrollContainer.heightInc {
  max-height: calc(100vh - 310px) !important;
}
.approverEmployeeCard {
  // width:220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.loginContainer {
  height: 100vh;
}
.bgi {
  width: 100%;
  // max-width: 1000px;
  // background: linear-gradient(180deg,#041e41,#0d088b);
  // background: linear-gradient(130deg, #0d088b,#008eff);
  // background: linear-gradient(180deg, #009ef7, #044264);
  height: 100vh;
  // position: relative;
  // background-image: url(../../../../src/localMedia/bg.jpg);
  background: linear-gradient(132.42deg, #0d088b 7.66%, #0c0f91 14.74%, #008eff 98.26%);
  position: relative;
}
.bg-elements {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  mix-blend-mode: overlay;
}
.loginRightContainer {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
}
.shape {
  position: absolute;
}
.shape-1 {
  right: 50px;
  bottom: 70px;
}
.shape-2 {
  left: -119px;
  top: -60px;
}
.shape-3 {
  right: 70px;
  top: 75px;
}
.shape-4 {
  top: 150px;
  left: 200px;
  width: 150px;
  height: 150px;
}
.shape-5 {
  top: 50%;
  left: 50px;
}
.shape-6 {
  bottom: 70px;
  left: 45px;
}
.shape-7 {
  top: 40%;
  right: -60px;
}
.shape-8 {
  bottom: 0;
  right: 0;
}
.shape-9 {
  left: 250px;
  bottom: -500px;
}
.shape-10 {
  right: -50px;
  top: -50px;
}
.shape-11 {
  bottom: 100px;
  left: 350px;
}
.shape-12 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.loginForm {
  max-width: 400px;
}
.firstImages {
  width: 100%;
  height: 100vh;
  mix-blend-mode: overlay;
  object-fit: cover;
  opacity: 0.1;
}
.logo {
  width: 300px;
}
.lft-pt {
  padding-top: 150px;
  position: relative;
  z-index: 1;
  max-width: 1000px;
  width: 100% !important;
  text-align: center;
}
.logoText {
  color: #dbdbdb;
  padding-top: 50px;
  width: 100%;
}
.pt5 {
  padding-top: 5px;
}
// .loginRightContainer::before {

//   background: url(../../../localMedia/forLoginBg/Vector.png);

// }
.aside:not(.dropdown-menu.show) .menu-item .menu-link {
  flex-direction: column;
}
.aside .dropdown-menu.show .menu-item .menu-link {
  flex-direction: row;
}
.aside .dropdown-menu.show .menu-item .menu-link .menu-title {
  white-space: nowrap;
}
.aside .menu-item .menu-link .menu-icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-right: 0;
  padding-bottom: 5px;
}
.aside-fixed .aside {
  overflow: unset !important;
}
.hover-scroll-overlay-y {
  overflow-y: unset !important;
}
.customSideBar.dropdown-menu {
  background: #273143;
  outline: 2px solid #273143;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

.popup-content {
  border: unset !important;
}

[role='tooltip'].popup-content {
  box-shadow: var(--kt-menu-dropdown-box-shadow) !important;
}
html[data-theme='dark'] .popup-content {
  background-color: var(--kt-menu-dropdown-bg-color);
}
.orgLogo {
  // width: 80px;
  height: 45px;
}
.footer {
  width: calc(100% - 100px);
  position: fixed;
  bottom: 0;
  z-index: 100;
  box-shadow: var(--kt-box-shadow-xs);
}
.footerLogo {
  width: 130px;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  margin: 8px !important;
  padding: 3px;
  font-family: 'Jost';
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.5px;
}
// input[type="text"]{Fc
//   text-transform: capitalize;
// }
.mobileHeaderLogo {
  width: 26px;
  height: auto;
}
.viewPassword {
  position: absolute;
  right: 12px;
  top: 18px;
  &.is-invalid {
    right: 35px;
  }
}
.userName {
  letter-spacing: 2px;
  width: 40px;
  height: auto;
}
.example-custom-input {
  background: url('../../../localMedia/cal2.svg') no-repeat right;
  background-size: 19px;
  background-origin: content-box;
}
.fs-30 {
  font-size: 30px;
}
.fs-40 {
  font-size: 40px;
}
.minHeight50 {
  min-height: 50px;
}
.rmdp-container {
  min-width: 100%;
}
.zIndexForMulti {
  z-index: 1055 !important;
}
.modal-backdrop.show:nth-last-child(2) {
  z-index: 1055;
}
.tableCheckBox {
  border-color: #737373;
}
/************Edge browser remove view password default behaviour********************/
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
/************Edge browser remove view password default behaviour********************/
@-moz-document url-prefix() {
  .disabledColorForApprover {
    textarea {
      height: 85px;
    }
  }
}

.pr136 {
  padding-right: 140px;
}
.lege {
  display: flex;
  justify-content: center;
}

ul.graphLegend1 > :nth-child(1) {
  color: #3cb043;
  padding: 0 4px;
}
ul.graphLegend2 > :nth-child(1) {
  color: #0000ff;
  padding: 0 4px;
}
ul.graphLegend3 > :nth-child(1) {
  color: #ffc300;
  padding: 0 4px;
}
.side-bar-table {
  table-layout: fixed;
}
.side-bar-table td,
.tableTdBreakWord {
  white-space: break-spaces;
}

.table-responsive table.table-two-headers-sticky thead tr:nth-child(2) th {
  top: 44px !important;
}
.pr-150 {
  padding-right: 150px;
}
.padding-down td {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.reportTab {
  max-height: calc(100vh - 260px);
}
.tableVerticalAlignTop td {
  vertical-align: top;
}
.tableVerticalAlignTop .td-50 {
  width: 50%;
  max-width: 50%;
}
.btn-height {
  height: 28.5px !important;
}
.freezeTable thead tr th:nth-child(1) {
  position: sticky;
  left: 0;

  z-index: 97;
}

.freezeTable thead tr th:nth-child(2) {
  position: sticky;
  left: 118px;
  z-index: 97;
}

.freezeTable thead tr th:nth-child(3) {
  position: sticky;
  left: 251px;
  z-index: 97;
}

.freezeTable thead tr th:nth-child(4) {
  position: sticky;
  left: 400px;
  z-index: 97;
}

.freezeTable tbody td:nth-child(1) {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
}

.freezeTable tbody td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  left: 118px;
}

.freezeTable tbody td:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  left: 251px;
}

.freezeTable tbody td:nth-child(4) {
  position: -webkit-sticky;
  position: sticky;
  left: 400px;
}
.freezeTable tbody td:nth-child(1),
.freezeTable tbody td:nth-child(2),
.freezeTable tbody td:nth-child(3),
.freezeTable tbody td:nth-child(4) {
  background: var(--kt-card-bg);
}
.freezeTable2 thead tr th:nth-child(1) {
  position: sticky;
  left: 0;

  z-index: 97;
}

.freezeTable2 thead tr th:nth-child(2) {
  position: sticky;
  left: 131px;
  z-index: 97;
}

.freezeTable2 thead tr th:nth-child(3) {
  position: sticky;
  left: 251px;
  z-index: 97;
}

.freezeTable2 thead tr th:nth-child(4) {
  position: sticky;
  left: 399px;
  z-index: 97;
}

.freezeTable2 tbody td:nth-child(1) {
  position: -webkit-sticky;
  position: sticky;
  background: var(--kt-card-bg);
  left: 0px;
}

.freezeTable2 tbody td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  background: var(--kt-card-bg);
  left: 131px;
}

.freezeTable2 tbody td:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  background: var(--kt-card-bg);
  left: 251px;
}

.freezeTable2 tbody td:nth-child(4) {
  position: -webkit-sticky;
  position: sticky;
  background: var(--kt-card-bg);
  left: 399px;
}
.table-responsive table th {
  position: sticky;
  top: 0;
  white-space: nowrap;
  background-color: var(--kt-card-bg);
  z-index: 96;
}
.tableGreenBg td {
  background: #e8fff3;
}
.freezeTable tbody .tableGreenBg td:nth-child(1),
.freezeTable tbody .tableGreenBg td:nth-child(2),
.freezeTable tbody .tableGreenBg td:nth-child(3),
.freezeTable tbody .tableGreenBg td:nth-child(4) {
  background: #e8fff3;
}
.freezeTable2 tbody .tableGreenBg td:nth-child(1),
.freezeTable2 tbody .tableGreenBg td:nth-child(2),
.freezeTable2 tbody .tableGreenBg td:nth-child(3),
.freezeTable2 tbody .tableGreenBg td:nth-child(4) {
  background: #e8fff3;
}
.tableOrangeBg td {
  background: #ffd18e;
}
.freezeTable tbody .tableOrangeBg td:nth-child(1),
.freezeTable tbody .tableOrangeBg td:nth-child(2),
.freezeTable tbody .tableOrangeBg td:nth-child(3),
.freezeTable tbody .tableOrangeBg td:nth-child(4) {
  background: #ffd18e;
}
.freezeTable2 tbody .tableOrangeBg td:nth-child(1),
.freezeTable2 tbody .tableOrangeBg td:nth-child(2),
.freezeTable2 tbody .tableOrangeBg td:nth-child(3),
.freezeTable2 tbody .tableOrangeBg td:nth-child(4) {
  background: #ffd18e;
}

[data-theme='dark'] {
  .tableOrangeBg td,
  .freezeTable2 tbody .tableOrangeBg td:nth-child(1),
  .freezeTable2 tbody .tableOrangeBg td:nth-child(2),
  .freezeTable2 tbody .tableOrangeBg td:nth-child(3),
  .freezeTable2 tbody .tableOrangeBg td:nth-child(4),
  .tableGreenBg td,
  .freezeTable tbody .tableGreenBg td:nth-child(1),
  .freezeTable tbody .tableGreenBg td:nth-child(2),
  .freezeTable tbody .tableGreenBg td:nth-child(3),
  .freezeTable tbody .tableGreenBg td:nth-child(4),
  .freezeTable2 tbody .tableGreenBg td:nth-child(1),
  .freezeTable2 tbody .tableGreenBg td:nth-child(2),
  .freezeTable2 tbody .tableGreenBg td:nth-child(3),
  .freezeTable2 tbody .tableGreenBg td:nth-child(4) {
    color: #2d2d2d;
  }
}

.smallTextField {
  padding: 14px;
  height: 35px;
}
ul.activeColor li.nav-item button.nav-link.active {
  color: var(--kt-primary) !important;
}

.highLightRow:hover,
.freezeTable2 tbody tr:hover td:nth-child(0),
.freezeTable2 tbody tr:hover td:nth-child(1):not(.tableOrangeBg td),
.freezeTable2 tbody tr:hover td:nth-child(2):not(.tableOrangeBg td),
.freezeTable2 tbody tr:hover td:nth-child(3):not(.tableOrangeBg td),
.freezeTable2 tbody tr:hover td:nth-child(4):not(.tableOrangeBg td) {
  background: var(--kt-primary-light) !important;
  cursor: pointer;
}
.overFlowForTextField .rbt-input-multi {
  overflow-y: auto;
  max-height: 84px !important;
}
@media only screen and (max-width: 767px) {
  .margin-left-250 {
    margin-left: 0;
  }
  .approverSearchCard {
    width: 100%;
  }
  .timesheetScrollContainer.timesheetScrollContainerForSingleProject {
    max-height: calc(100vh - 300px);
  }
}
@media screen and (max-device-width: 767px) and (orientation: landscape) {
  .timesheetScrollContainer {
    max-height: unset !important;
  }
  .timesheetScrollContainer.timesheetScrollContainerForSingleProject {
    max-height: unset !important;
  }
}
@media only screen and (max-width: 991px) {
  .footer {
    width: 100%;
  }
}

.modal-max-w-img {
  max-width: 100%;
  img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
  }
}
